/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';

const container = css`
  ${tw`p-4-md pr-0 text-grey`};
`;
const title = css`
  ${tw`uppercase text-center font-light text-2xl text-grey-light mb-6`};
`;
const link = css`
  ${tw`block no-underline font-medium text-l text-grey-light text-red-light pb-2`};
`;
const callout = css`
  ${tw`font-medium text-xl text-red-light`};
`;

const AffiliateLink = props => {
  const { url, label } = props;
  return (
    <li>
      <a css={link} href={url} alt={label}>
        {label}
      </a>
    </li>
  );
};

AffiliateLink.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
};

const Affiliations = () => {
  const data = useStaticQuery(graphql`
    query AffiliationsQuery {
      site: siteJson {
        contact {
          company
        }
        affiliateLinks {
          label
          url
        }
      }
    }
  `);
  const { contact, affiliateLinks } = data.site;

  return (
    <div css={container}>
      <h2 css={title}>Affiliations</h2>
      <p>
        <span css={callout}>{contact.company} </span> is a division of Frying
        Pan Anglers. Click the links below to visit our other properties.
      </p>
      <ul css={tw`list-reset`}>
        {affiliateLinks.map((affiliate, k) => (
          <AffiliateLink url={affiliate.url} label={affiliate.label} key={k} />
        ))}
      </ul>
    </div>
  );
};

export default Affiliations;
