/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { jsx, css } from '@emotion/core';

import BulmaNav from './bulma-nav';
import { FacebookIcon, InstagramIcon, GoogleIcon } from './icons';

const headerContainer = css`
  ${tw`
    flex
    pt-2
    justify-between
    bg-grey-lighter
`};
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23eeeeee' fill-opacity='0.45'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
`;
const brandContainer = css`
  ${tw`px-4 block`};
`;
const socialIcons = css`
  ${tw`px-4 pt-2 content-center`};
`;
const linkContainer = css`
  ${tw`pt-1 text-center text-grey-dark text-base`};
`;
const iconContainer = css`
  ${tw`px-2`};
`;

const Header = () => {
  const data = useStaticQuery(graphql`
    query SiteHeaderQuery {
      site: siteJson {
        social {
          facebook {
            url
          }
          instagram {
            url
          }
          google {
            url
          }
        }
      }
      logoImg: file(relativePath: { eq: "images/sja-logo@2x.png" }) {
        childImageSharp {
          fixed(width: 153) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  return (
    <header>
      <div css={headerContainer}>
        <div css={brandContainer}>
          <div>
            <Link to="/">
              <Img
                fixed={data.logoImg.childImageSharp.fixed}
                title="Logo Image"
                alt="The San Juan Angler Logo"
              />
            </Link>
          </div>
        </div>
        <div css={socialIcons}>
          <div>
            <a href={data.site.social.facebook.url}>
              <span css={iconContainer}>
                <FacebookIcon class="icon-lg-grey" />
              </span>
            </a>
            <a href={data.site.social.instagram.url}>
              <span css={iconContainer}>
                <InstagramIcon class="icon-lg-grey" />
              </span>
            </a>
            <a href={data.site.social.google.url}>
              <span css={iconContainer}>
                <GoogleIcon class="icon-lg-grey" />
              </span>
            </a>
          </div>
          <div css={linkContainer}>(970) 382-9978</div>
        </div>
      </div>
      <BulmaNav />
    </header>
  );
};

export default Header;
