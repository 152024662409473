/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { jsx, css } from '@emotion/core';

import AddressCard from './address-card';
import { FacebookIcon, InstagramIcon, GoogleIcon } from './icons';

const container = css`
  ${tw`p-4-md pl-0 text-grey`};
`;
const title = css`
  ${tw`uppercase text-center font-light text-2xl text-grey-light mb-6`};
`;
const findUsItems = css`
  ${tw`list-reset mt-2`};
`;
const lineItem = css`
  ${tw`pb-3`};
`;
const socialIcon = css`
  ${tw`flex pr-4`};
`;
const socialLabel = css`
  ${tw`pl-4 pt-1 text-red-light`};
`;
const socialLink = css`
  ${tw`no-underline`};
`;

const FindUs = () => {
  const data = useStaticQuery(graphql`
    query FindUsQuery {
      site: siteJson {
        contact {
          company
        }
        social {
          facebook {
            title
            url
          }
          instagram {
            title
            url
          }
          google {
            title
            url
          }
        }
      }
    }
  `);
  const { company } = data.site.contact;
  const { social } = data.site;

  return (
    <div css={container}>
      <h2 css={title}>Find Us</h2>
      <h3 css={tw`mb-4 text-red-light font-medium text-xl`}>{company}</h3>
      <AddressCard />
      <ul css={findUsItems}>
        <li css={lineItem}>
          <a css={socialLink} href={social.facebook.url}>
            <div css={socialIcon}>
              <FacebookIcon class="icon-lg-grey" />
              <div css={socialLabel}>{social.facebook.title}</div>
            </div>
          </a>
        </li>
        <li css={lineItem}>
          <a css={socialLink} href={social.instagram.url}>
            <div css={socialIcon}>
              <InstagramIcon class="icon-lg-grey" />
              <div css={socialLabel}>{social.instagram.title}</div>
            </div>
          </a>
        </li>
        <li css={lineItem}>
          <a css={socialLink} href={social.google.url}>
            <div css={socialIcon}>
              <GoogleIcon class="icon-lg-grey" />
              <div css={socialLabel}>{social.google.title}</div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FindUs;
