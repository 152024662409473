/* eslint-disable */
/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { Link } from 'gatsby';
import { jsx, css } from '@emotion/core';

const link = css`
  ${tw`uppercase no-underline text-grey-light font-normal hover:text-white p-2 text-base`}
 `;

const NavLink = (props) => (
  <Link to={props.to} css={link} className="navbar-item" activeClassName="is-active">
    {props.label}
  </Link>
);

const BulmaNav = class extends React.Component {
  componentDidMount() {
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    if ($navbarBurgers.length > 0) {
      $navbarBurgers.forEach((el) => {
        el.addEventListener('click', () => {
          const target = el.dataset.target;
          const $target = document.getElementById(target);
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
        });
      });
    }
  }

  render() {
    return (
      <nav className="navbar top-navbar" role="navigation" aria-label="main navigation">
        <div>
          <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navMenu">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div id="navMenu" className="navbar-menu" css={tw`pr-4`} aria-expanded="false">
          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable">
              <a css={link} className="navbar-link">
                Fishing Reports
              </a>
              <div className="navbar-dropdown">
                <NavLink to="/fishing-reports/san-juan-river" label="San Juan Fishing Report" />
                <NavLink to="/fishing-reports/animas-river" label="Animas Fishing Report" />
                <NavLink to="/fishing-reports/los-pinos-river" label="Los Pinos Fishing Report" />
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a css={link} className="navbar-link">
                Local Waters
              </a>
              <div className="navbar-dropdown">
                <NavLink to="/local-waters/san-juan-river" label="The San Juan River" />
                <NavLink to="/local-waters/animas-river" label="The Animas River" />
                <NavLink to="/local-waters/los-pinos-river" label="Los Pinos River" />
                <NavLink to="/local-waters/backcountry" label="The Backcountry" />
              </div>
            </div>
            <NavLink to="/guided-trips" label="Guided Trips" />
            <NavLink to="/classes" label="Classes" />
            <NavLink to="/our-shop" label="Our Shop" />
            {/* <NavLink to="/gallery/" label="Gallery" />
            <NavLink to="/contact/" label="Contact" /> */}
          </div>
        </div>
      </nav>
    );
  }
};

export default BulmaNav;
