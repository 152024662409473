/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';

const style = css`
  ${tw`uppercase font-normal text-2xl text-red mb-6`};
`;

const Title = props => {
  const { text } = props;
  return <h1 css={style}>{text}</h1>;
};

Title.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Title;
