/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';
import { ClockIcon, LocationIcon, PhoneIcon, EmailIcon } from './icons';

const addressItem = css`
  ${tw`pb-3 text-large flex`};
`;
const icon = css`
  ${tw`relative pr-4`};
  top: 2px;
`;
const addressIcon = css`
  ${tw`relative pr-4`};
  top: 8px;
`;

const ShopHours = props => {
  const { hours } = props;
  return (
    <li css={addressItem}>
      <span css={icon}>
        <ClockIcon class="icon-sm-grey" />
      </span>
      <span>{hours}</span>
    </li>
  );
};

ShopHours.propTypes = {
  hours: PropTypes.string,
};

const AddressCard = () => {
  const data = useStaticQuery(graphql`
    query AddressCardQuery {
      site: siteJson {
        contact {
          address1
          city
          state
          zipcode
          phone
          email
        }
        hours
      }
    }
  `);
  const { contact } = data.site;
  const { hours } = data.site;
  return (
    <div css={tw`mt-4`}>
      <ul css={tw`list-reset`}>
        <li css={addressItem}>
          <div css={addressIcon}>
            <LocationIcon class="icon-sm-grey" />
          </div>
          <div>
            {contact.address1} <br />
            {contact.city}, {contact.state} {contact.zipcode}
          </div>
        </li>
        <li css={addressItem}>
          <span css={icon}>
            <PhoneIcon class="icon-sm-grey" />
          </span>
          <span>{contact.phone}</span>
        </li>
        <li css={addressItem}>
          <span css={icon}>
            <EmailIcon class="icon-sm-grey" />
          </span>
          <span>
            <a href={`mailto:${contact.email}`}>{contact.email}</a>
          </span>
        </li>
        {hours.map((hour, k) => (
          <ShopHours hours={hour} key={k} />
        ))}
      </ul>
    </div>
  );
};

export default AddressCard;
