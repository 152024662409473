/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { jsx, css } from '@emotion/core';
import Affiliations from './affiliations';
import FindUs from './find-us';

const footerContainer = css`
  ${tw`
  container
  w-full
  bg-grey-darkest
  text-grey-light`};
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%233a444e' fill-opacity='0.9'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
`;

const Footer = () => (
  <footer css={footerContainer}>
    <div className="section">
      <div className="columns">
        <div className="column is-one-third">
          <FindUs />
        </div>
        <div className="column is-one-third" />
        <div className="column is-one-third">
          <Affiliations />
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
