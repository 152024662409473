/* eslint no-unused-vars: 0 */
/* global tw */
import React, { Fragment } from 'react';
import { Global, jsx, css } from '@emotion/core';
import PropTypes, { element } from 'prop-types';

import Header from './header';
import Footer from './footer';
import '../scss/app.scss';

const globalStyles = css`
  html {
    font-size: 16px;
  }
  body {
    ${tw`font-sans text-grey-darkest text-lg font-normal`};
  }
  p {
    ${tw`mt-0 mb-6 font-light leading-normal`};
  }
  a {
    ${tw`no-underline`};
    color: inherit;
  }
  .section {
    ${tw`px-4 py-4 md:px-16`};
  }
  .icon-sm-grey {
    ${tw`fill-current text-grey-dark h-4 w-4`};
  }
  .icon-lg-grey {
    ${tw`fill-current text-grey-dark h-6 w-6`};
  }
`;

const PageContainer = ({ children }) => (
  <Fragment>
    <Global styles={globalStyles} />
    <Header />
    <div>{children}</div>
    <Footer />
  </Fragment>
);

PageContainer.propTypes = {
  children: PropTypes.array.isRequired,
};

export default PageContainer;
